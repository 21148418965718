<template>
  <div>
    <article>
      <section>
        <div class="add_intake_dialogs">
          <el-dialog
            title="Edit Intake"
            :visible.sync="showEditIntakeDialog"
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div
              v-loading="loading || submitting"
              class="batch_request_form"
            >
              <el-form
                :model="batchForm"
                :rules="rules"
                ref="batchForm"
                class="demo-batchForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label>Year of Intake</label>
                    <el-form-item prop="year">
                      <el-date-picker
                        style="width: 100%"
                        type="year"
                        :value-format="'yyyy'"
                        @keyup.enter="editBatch"
                        placeholder="Pick a year"
                        v-model="batchForm.year"
                      ></el-date-picker>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Month of Intake</label>
                    <el-form-item prop="month">
                      <el-date-picker
                        style="width: 100%"
                        type="month"
                        :format="'MMMM'"
                        :value-format="'M'"
                        @keyup.enter="editBatch"
                        placeholder="Pick a month"
                        v-model="batchForm.month"
                      ></el-date-picker>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label>Choose programme</label>
                    <el-form-item prop="course_id">
                      <el-select
                        filterable
                        v-model="batchForm.course_id"
                        placeholder="Choose programme"
                        @change="getCurricula(batchForm.course_id)"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="c in courses"
                          :key="c.course_id"
                          :value="c.course_id"
                          :label="c.title"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Cohort</label>
                    <el-form-item prop="cohort">
                      <el-input
                        v-model="batchForm.cohort"
                        placeholder="Cohort"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Choose Curriculum</label>
                    <el-form-item prop="course_curriculum_id">
                      <el-select
                        filterable
                        v-model="batchForm.course_curriculum_id"
                        placeholder="Choose Curriculum"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="c in curricula"
                          :key="c.course_curriculum_id"
                          :value="c.course_curriculum_id"
                          :label="c.title"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Choose Transcript curriculum</label>
                    <el-form-item prop="transcript_course_curriculum_id">
                      <el-select
                        filterable
                        v-model="batchForm.transcript_curriculum_id"
                        placeholder="Choose Transcript Curriculum"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="transcriptCurriculum in transcriptCurricula"
                          :key="transcriptCurriculum.transcript_curriculum_id"
                          :value="transcriptCurriculum.transcript_curriculum_id"
                          :label="transcriptCurriculum.title"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>

                <el-form-item class="text-center">
                  <el-button
                    style="
                        background-color: var(--el-app-primary);
                        color: white;
                      "
                    v-on:click="editBatch('batchForm')"
                  >
                    Edit Intake
                  </el-button>
                </el-form-item>

                <hr />

                <div class="text-center">
                  <div class="text-center">
                    <small></small>
                  </div>
                  <div class="text-center">
                    <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </el-form>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      loading: false,
      showEditIntakeDialog: false,
      loadingError: false,
      submitting: false,
      isBusy: false,
      courses: [],
      curricula: [],
      transcriptCurricula: [],
      course_intake_batch_id: "",
      course_id: "",
      value2: "",
      value3: "",

      batchForm: {
        year: "",
        month: "",
        course_id: "",
        cohort: "1.1",
        course_curriculum_id: "",
        transcript_curriculum_id: "",
      },

      rules: {
        year: [
          { required: true, message: "Year is required", trigger: "change" },
        ],
        month: [
          { required: true, message: "Month is required", trigger: "change" },
        ],
        course_id: [
          {
            required: true,
            message: "Programme is required",
            trigger: "change",
          },
        ],
        cohort: [
          { required: true, message: "Cohort is required", trigger: "blur" },
        ],
        course_curriculum_id: [
          {
            required: true,
            message: "Curriculum is required",
            trigger: "change",
          },
        ],
      },
    };
  },

  props: {
    editIntakeDialog: { type: Boolean, required: true },
    intakeData: { type: Object, required: true },
  },

  watch: {
    editIntakeDialog() {
      if (this.editIntakeDialog == true) {
        this.course_intake_batch_id = this.intakeData.course_intake_batch_id;
        this.course_id = this.intakeData.course.course_id;
        this.getCurricula(this.course_id);
        this.getCourseAndCurricula()
        this.batchForm.year = `${this.intakeData.year}`;
        this.batchForm.month = `${this.intakeData.month}`;
        this.batchForm.course_id = this.intakeData.course.course_id;
        this.batchForm.cohort = this.intakeData.cohort;
        this.batchForm.course_curriculum_id = this.intakeData.course_curriculum_id;
        this.batchForm.transcript_curriculum_id = this.intakeData.transcript_curriculum_id;
        this.showEditIntakeDialog = true;
      } else {
        this.showEditIntakeDialog = false;
      }
    },
  },

  methods: {
    handleClose(value) {
      value();
      this.$emit("closeIntakeEditDialog");
      this.clearInputs();
    },

    clearInputs() {
      this.batchForm.year = "";
      this.batchForm.month = "";
      this.batchForm.course_id = "";
      this.batchForm.cohort = "1.1";
      this.batchForm.course_curriculum_id = "";
      this.batchForm.transcript_curriculum_id = "";
    },

    async editBatch(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.submitting = true;
            let request = await this.$http.patch(
              `course-intake-batches/update-one/${this.course_intake_batch_id}`,
              {
                year: this.batchForm.year,
                month: this.batchForm.month,
                course_id: this.batchForm.course_id,
                course_curriculum_id: this.batchForm.course_curriculum_id,
                transcript_curriculum_id: this.batchForm.transcript_curriculum_id === "" ? null : this.batchForm.transcript_curriculum_id,
              }
            );
            if (
              request.data.success &&
              request.data.message == "COURSE INTAKE BATCH UPDATED SUCCESSFULLY"
            ) {
              this.$refs[formName].resetFields();
              this.submitting = false;
              this.$emit("re-fresh");
              this.$emit("closeIntakeEditDialog");
              this.showEditIntakeDialog = false;
              this.showSuccessMessage("Success", "Intake added successfully");
            } else {
              this.$rollbar.warning(
                "ADMIN FRONT END: Unexpected API response while getting server response",
                request.data,
                request
              );
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.showFailedMessage(
                "Connection failed",
                "A network error occurred, please try again."
              );
            }
            if (error.response) {
              if (
                error.response.data.message === "COURSE_INTAKE_BATCH_EXISTS"
              ) {
                this.showWarningMessage(
                  "Duplicate Intake",
                  "Intake already exists"
                );
              } else if (error.response.data.message == "DUPLICATE COHORT") {
                this.showWarningMessage(
                  "Duplicate Cohort",
                  `The Cohort ${error.response.data.intake} already exists, please review it and try again`
                );
              } else if (error.response.data.message == "INVALID_COHORT") {
                this.showWarningMessage(
                  "Invalid Cohort",
                  "The cohort you entered is invalid"
                );
              } else if (
                error.response.data.message ==
                "MONTH SHOULD BE MARCH, JULY, SEPTEMBER OR NOVEMBER"
              ) {
                return this.showWarningMessage(
                  "Wrong Month",
                  "Months should be March, July, September or November"
                );
              }
            } else {
              this.showFailedMessage(
                "Unable to Edit Intakes",
                "An unexpected error occurred, please try again"
              );
            }
          } finally {
            this.submitting = false;
          }
        } else {
          return false;
        }
      });
    },

    async getCourseAndCurricula() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `course-intake-batches/before-add-or-edit/${this.course_id}`
        );
        if (
          request.data.success &&
          request.data.message == "PREREQUISITES FETCHED SUCCESSFULLY"
        ) {
          this.courses = request.data.courses_names;
          // this.curricula = request.data.curricula;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          return;
        } else {
          return this.showFailedMessage(
            "Unable to load  Courses and Curricula",
            "An unexpected error occurred, please try again"
          );
        }
      } finally {
        this.loading = false;
      }
    },

    async getCurricula(course_id){
      this.batchForm.course_curriculum_id = "";
      this.batchForm.intakeIdToExtendBillingStructures = ""
      let request = await this.httpRequest({
        method: "GET",
        url: `course-intake-batches/on-before-upsert/${course_id}`,
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "loadingError",
        showSuccessMessage: false,
      });

      if (
        request &&
        request.success &&
        request.message == "On Before Intake Upsert data fetched Successfully"
      ) {
          this.curricula = request.curricula;
          this.transcriptCurricula = request.transcript_curricula;
      }
    },

    // async getCurricula() {
    //   try {
    //     this.loading = true;
    //     this.loadingError = false;
    //     this.batchForm.course_curriculum_id = "";
    //     let request = await this.$http.get(
    //       `examinations/fetch-course-curricula-names/${this.batchForm.course_id}`
    //     );
    //     if (
    //       request.data.success &&
    //       request.data.message == "CURRICULA NAMES FETCHED SUCCESSFULLY"
    //     ) {
    //       this.curricula = request.data.curricula;
    //     } else {
    //       this.$rollbar.warning(
    //         "ADMIN FRONT END: Unexpected API response while getting server response",
    //         request.data,
    //         request
    //       );
    //       throw "UNEXPECTED_RESPONSE";
    //     }
    //   } catch (error) {
    //     if (error.message == "Network Error") {
    //       return this.showFailedMessage(
    //         "Connection failed",
    //         "A network error occurred, please try again."
    //       );
    //     }
    //     if (error.response) {
    //       if (
    //         error.response.data.message === "INVALID ID SUPPLIED FOR DECRYPTION"
    //       ) {
    //         return;
    //       }
    //     } else {
    //       return this.showFailedMessage(
    //         "Unable to load Curricula",
    //         "An unexpected error occurred, please try again"
    //       );
    //     }
    //   } finally {
    //     this.loading = false;
    //   }
    // },
  },
};
</script>

<style>
.batch_request_form .el-select .el-input__inner {
  cursor: pointer;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.batch_request_form .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

.batch_request_form .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.batch_request_form .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.batch_request_form .el-input--suffix .el-input__inner {
  padding-right: 10px;
}

.add_intake_dialogs .el-dialog {
  width: 60%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .add_intake_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .add_intake_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .add_intake_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .add_intake_dialogs .el-dialog {
    width: 90%;
  }
}
</style>