<template>
  <div class="exam-permits text-left">
    <el-dialog
      :visible.sync="isShowExaminationPermitsDialog"
      :before-close="handleClose"
      width="30%"
    >
      <div v-loading="loading || isBusy">
        <el-form
          :model="permitsRuleForm"
          :rules="permitsRules"
          ref="permitsRuleForm"
          label-width="120px"
          class="demo-permitsRuleForm"
        >
          <div class="row">
            <div class="col-md-12">
              <label>Download Examination Permits</label>
              <el-form-item
                prop="examination_permits"
                style="width: 100%;"
              >
                <el-select
                  v-model="permitsRuleForm.examination_permits"
                  placeholder="Select"
                  style="width: 100%;"
                >
                  <el-option
                    v-for="permits in examination_permits"
                    :key="permits.student_examination_set_permit_id"
                    :label="permits.examination_set.title"
                    :value="permits.student_examination_set_permit_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <el-form-item>
                <el-button
                  type="primary"
                  @click="downloadExaminationPermits"
                  style="width: 100%"
                >Download Permit</el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isShowExaminationPermitsDialog: false,
      loading: false,
      isBusy: false,
      loadingError: false,
      examination_permits: [],

      permitsRuleForm: {
        examination_permits: "",
      },

      permitsRules: {
        examination_permits: [
          {
            required: true,
            message: "Please select examination permits",
            trigger: "change",
          },
        ],
      },
    };
  },

  props: {
    isExaminationPermitsDialogVisible: {
      required: true,
      type: Boolean,
    },

    studentId: {
      type: String,
      required: true,
    },
  },

  watch: {
    isExaminationPermitsDialogVisible() {
      if (this.isExaminationPermitsDialogVisible) {
        this.isShowExaminationPermitsDialog = true;
        this.getAllExaminationPermits();
      } else {
        this.isShowExaminationPermitsDialog = false;
      }
    },
  },

  methods: {
    handleClose(done) {
      done();
      this.studentIdCardIssueDate = "";
      this.$emit("close-dialog");
    },

    async getAllExaminationPermits() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.get(
          `students/examination-set-permits/${this.studentId}`
        );

        if (
          request.data.success &&
          request.data.message ==
            "STUDENT EXAMINATION SET PERMITS FETCHED SUCCESSFULLY"
        ) {
          this.examination_permits = request.data.examination_permits;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.loadingError = true;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }

        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (
            error.response.data.message ===
            "STUDENT HAS NO EXAMINATION SET PERMITS"
          ) {
            this.loadingError = true;
            return this.showWarningMessage(
              "No Permits",
              "Unable to find Student Permits for the selected Student, ensure they are cleared and try again"
            );
          }
        } else {
          this.loadingError = true;
          this.showFailedMessage(
            "Unable to load Resources",
            "An unexpected error occurred, please try again"
          );
        }
      } finally {
        this.loading = false;
      }
    },

    async downloadExaminationPermits() {
      try {
        //Show Loading state
        this.isBusy = true;

        let response = await this.$http.get(
          `students/examination-permits-for-examination-set/${this.permitsRuleForm.examination_permits}`
        );
        if (
          response.data.success &&
          response.data.message ==
            "STUDENT EXAM SET PERMIT GENERATED SUCCESSFULLY"
        ) {
          const link = document.createElement("a");
          link.href = response.data.path_url;
          link.click();
          this.showSuccessMessage(
            "Permit Downloaded",
            "The Selected Permit was successfully retrieved"
          );
        } else {
          //Report the Error to an error reporting service
          this.$rollbar.warning("STUDENTS FRONT END: Unexpected API response", {
            response: response.data,
            request: response,
          });

          //Throw this as an error
          throw "UNEXPECTED_RESPONSE_RECEIVED";
        }
      } catch (error) {
        //Handle Network Errors
        if (error.message === "Network Error") {
          return this.showFailedMessage(
            "Connection Failed",
            "Unable to Connect. Please check your Internet Connection and try again."
          );
        }
        //If the API returns other status codes besides 2xx, Handle these responses
        if (error.response) {
          if (error.response.data.message === "NO ACADEMIC YEAR") {
            return this.showWarningMessage(
              "Missing Configurations",
              "Academic Year configuration is missing, please contact admin"
            );
          } else if (error.response.data.message === "NO EXAMINATIONS FOUND") {
            this.showWarningMessage(
              "No Examinations Found",
              "Unable to find any Examinations for the selected Permit"
            );
          } else if (error.response.data.message === "STUDENT HAS NO SESSION") {
            return this.showWarningMessage(
              "No Session",
              "No Student session was found for the selected student"
            );
          } else if (error.response.data.message === "STUDENT NOT FOUND") {
            return this.showWarningMessage(
              "Not Found",
              "Unable to find the selected student Account"
            );
          } else if (
            error.response.data.message === "STUDENT EXAM SET PERMIT NOT FOUND"
          ) {
            return this.showWarningMessage(
              "Permit Not Found",
              "We were unable to find the selected Exam Permit"
            );
          }
        } else {
          return this.showFailedMessage(
            "Unable to Download Permit",
            "An unexpected Error occurred, please try again"
          );
        }

        //TODO: Handle default callback
      } finally {
        //Hide Loading state
        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
</style>

<style>
.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}
.not-allowed {
  cursor: not-allowed !important;
  pointer-events: all !important;
}
select.browser-default {
  display: block !important;
  height: 30px !important;
  width: 105%;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  cursor: pointer;
}

.exam-permits .el-form-item__content {
  margin: 0px !important;
}
.exam-permits .el-dialog__title {
  font-weight: 500;
}

.el-select {
  width: 100%;
}

.exam-permits .el-select .el-input__inner {
  cursor: pointer;
  width: 100% !important;
  /* padding-left: 10px !important; */
}

.el-date-editor--date .el-input__inner,
.el-range-editor .el-input__inner {
  cursor: pointer;
  width: 100% !important;
  /* padding-left: 45px !important; */
}
.exam-permits .el-date-editor .el-range-separator {
  display: contents;
  font-size: 0.8em;
  width: 20px !important;
  white-space: nowrap;
}

.exam-permits
  .el-date-editor.el-range-editor.el-input__inner.el-date-editor--timerange {
  height: 45px !important;
  width: 100%;
}
</style>