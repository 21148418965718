<template>
  <div>
    <el-button
      style="background-color: var(--el-app-primary); color: white"
      class="z-depth-0"
      v-ripple="'rgba(255, 255, 255, 0.35)'"
      @click="openBatchModal"
    >
      Add Intake
    </el-button>
    <article>
      <section>
        <div class="add_intake_dialogs">
          <el-dialog
            title="Add Intake"
            :visible.sync="addBatchModal"
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div
              v-loading="loading || submitting"
              class="batch_request_form"
            >
              <el-form
                :model="batchForm"
                :rules="rules"
                ref="batchForm"
                class="demo-batchForm2"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label>Year of Intake</label>
                    <el-form-item prop="year">
                      <el-date-picker
                        style="width: 100%"
                        type="year"
                        :value-format="'yyyy'"
                        @keyup.enter="addBatch"
                        placeholder="Pick a year"
                        v-model="batchForm.year"
                      ></el-date-picker>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Month of Intake</label>
                    <el-form-item prop="month">
                      <el-date-picker
                        style="width: 100%"
                        type="month"
                        :format="'MMMM'"
                        :value-format="'M'"
                        @keyup.enter="addBatch"
                        placeholder="Pick a month"
                        v-model="batchForm.month"
                      ></el-date-picker>
                    </el-form-item>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label>Choose programme</label>
                    <el-form-item prop="course_id">
                      <el-select
                        filterable
                        v-model="batchForm.course_id"
                        placeholder="Choose programme"
                        style="width: 100%"
                        @change="getCurricula"
                      >
                        <el-option
                          v-for="c in courses"
                          :key="c.course_id"
                          :value="c.course_id"
                          :label="c.title"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Cohort</label>
                    <el-form-item prop="cohort">
                      <el-input
                        v-model="batchForm.cohort"
                        placeholder="Cohort"
                        disabled
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Choose Curriculum</label>
                    <el-form-item prop="course_curriculum_id">
                      <el-select
                        filterable
                        v-model="batchForm.course_curriculum_id"
                        placeholder="Choose Curriculum"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="c in curricula"
                          :key="c.course_curriculum_id"
                          :value="c.course_curriculum_id"
                          :label="c.title"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-6">
                    <label>Choose Transcript curriculum</label>
                    <el-form-item>
                      <el-select
                        filterable
                        v-model="batchForm.transcript_curriculum_id"
                        placeholder="Choose Transcript Curriculum"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="transcriptCurriculum in transcriptCurricula"
                          :key="transcriptCurriculum.transcript_curriculum_id"
                          :value="transcriptCurriculum.transcript_curriculum_id"
                          :label="transcriptCurriculum.title"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Extend Billing Structures from</label>
                    <el-form-item prop="">
                      <el-select
                        filterable
                        v-model="batchForm.intakeIdToExtendBillingStructures"
                        placeholder="Choose Intake to extend from"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="intake in intakes"
                          :key="intake.course_intake_batch_id"
                          :value="intake.course_intake_batch_id"
                          :label="intake.intake"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>

                <el-form-item class="text-center">
                  <el-button
                    style="
                        background-color: var(--el-app-primary);
                        color: white;
                      "
                    v-on:click="addBatch('batchForm')"
                  >
                    Add Intake
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-dialog>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      loading: false,
      addBatchModal: false,
      loadingError: false,
      submitting: false,
      isBusy: false,
      courses: [],
      curricula: [],
      transcriptCurricula: [],
      intakes: [],

      batchForm: {
        year: "",
        month: "",
        course_id: "",
        cohort: "1.1",
        course_curriculum_id: "",
        transcript_curriculum_id: "",
        intakeIdToExtendBillingStructures: "",
      },

      rules: {
        year: [
          { required: true, message: "Year is required", trigger: "change" },
        ],
        month: [
          { required: true, message: "Month is required", trigger: "change" },
        ],
        course_id: [
          {
            required: true,
            message: "Programme is required",
            trigger: "change",
          },
        ],
        cohort: [
          { required: true, message: "Cohort is required", trigger: "blur" },
        ],
        course_curriculum_id: [
          {
            required: true,
            message: "Curriculum is required",
            trigger: "change",
          },
        ],
        // transcript_course_curriculum_id: [
        //   {
        //     required: true,
        //     message: "Transcript curriculum is required",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },

  methods: {
    handleClose(value) {
      value();
      this.clearInputs();
    },

    openBatchModal() {
      this.getCourses();
      this.addBatchModal = true;
    },

    clearInputs() {
      this.batchForm.year = "";
      this.batchForm.month = "";
      this.batchForm.course_id = "";
      this.batchForm.cohort = "1.1";
      this.batchForm.course_curriculum_id = "";
      this.batchForm.transcript_curriculum_id = "";
      this.batchForm.intakeIdToExtendBillingStructures = ""
    },

    async addBatch(formName){
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let request = await this.httpRequest({
            method: "POST",
            url: `course-intake-batches/add`,
            loadingPropertyName: "submitting",
            showSuccessMessage: true,
            body: {
              year: this.batchForm.year,
              month: this.batchForm.month,
              course_id: this.batchForm.course_id,
              course_curriculum_id: this.batchForm.course_curriculum_id,
              transcript_curriculum_id: this.batchForm.transcript_curriculum_id === "" ? null : this.batchForm.transcript_curriculum_id,
              parent_course_intake_batch_id: this.batchForm.intakeIdToExtendBillingStructures || null
            },
          });
    
          if (
            request &&
            request.success &&
            request.message == "Course Intake Batch created successfully"
          ) {
              this.$refs[formName].resetFields();
              this.$emit("re-fresh");
              this.$emit("closeIntakeAddDialog");
              this.addBatchModal = false;
          }
          
        } else {
          return false;
        }
      });
    },

    async getCourses() {
      try {
        this.loading = true;
        this.loadingError = false;
        let request = await this.$http.post(`courses/fetch-all-course-names`);
        if (
          request.data.success &&
          request.data.message == "COURSES_NAMES_FETCHED_SUCCESSFULLY"
        ) {
          this.courses = request.data.courses;
        } else {
          this.$rollbar.warning(
            "ADMIN FRONT END: Unexpected API response while getting server response",
            request.data,
            request
          );
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        if (error.response) {
          if (
            error.response.data.message === "INVALID ID SUPPLIED FOR DECRYPTION"
          ) {
            return;
          }
        } else {
          return this.showFailedMessage(
            "Unable to load  Courses and Curricula",
            "An unexpected error occurred, please try again"
          );
        }
      } finally {
        this.loading = false;
      }
    },

    async getCurricula(){
      this.batchForm.course_curriculum_id = "";
      this.batchForm.intakeIdToExtendBillingStructures = ""
      let request = await this.httpRequest({
        method: "GET",
        url: `course-intake-batches/on-before-upsert/${this.batchForm.course_id}`,
        loadingPropertyName: "loading",
        errorLoadingPropertyName: "loadingError",
        showSuccessMessage: false,
      });

      if (
        request &&
        request.success &&
        request.message == "On Before Intake Upsert data fetched Successfully"
      ) {
          this.curricula = request.curricula;
          this.transcriptCurricula = request.transcript_curricula;
          this.intakes = request.intakes;
      }
    },
  },
};
</script>

<style>
.batch_request_form .el-select .el-input__inner {
  cursor: pointer;
  width: 100% !important;
  padding-left: 10px !important;
  text-transform: capitalize;
}
.batch_request_form .el-button--primary {
  color: #fff;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

.batch_request_form .el-input input {
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.batch_request_form .el-input input:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.batch_request_form .el-input--suffix .el-input__inner {
  padding-right: 10px;
}

.add_intake_dialogs .el-dialog {
  width: 60%;
}
/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .add_intake_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .add_intake_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .add_intake_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .add_intake_dialogs .el-dialog {
    width: 90%;
  }
}
</style>